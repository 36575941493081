export default Backbone.View.extend({

    el: '#content-container',

    events: {
        'click #go-to-create-info': 'scrollToCreateInfo',
        'click #create': 'switchHow',
        'click #invest': 'switchHow',
        'click #go-to-help': 'scrollToHelp'
    },

    initialize: async function () {
        $(window).on('unload', function() {
            $(window).scrollTop(0);
        });

        window.onunload = function(){ window.scrollTo(0,0); }

        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }

        const url = new URL(window.location.href);
        const section = url.searchParams.get("section");
        if (section === 'create-info') {
            this.scrollToCreateInfo();
        }

        const that = this;
        $('#go-to-create-info').on('click', function () {
            that.scrollToCreateInfo();
        });
    },

    switchHow: function (e) {
        const id = $(e.target).attr('id');
        switch (id) {
            case 'create':
                $('#invest').removeClass('landing__how-switch-item--active');
                $('#invest-items').removeClass('landing__how-items--active');
                $('#create').addClass('landing__how-switch-item--active');
                setTimeout(function () {
                    $('#invest-items').css('display', 'none');
                    $('#create-items').css('display', 'block');
                    setTimeout(function () {
                        $('#create-items').addClass('landing__how-items--active');
                    }, 5);
                }, 300);
                break;
            case 'invest':
                $('#create').removeClass('landing__how-switch-item--active');
                $('#create-items').removeClass('landing__how-items--active');
                $('#invest').addClass('landing__how-switch-item--active');
                setTimeout(function () {
                    $('#create-items').css('display', 'none');
                    $('#invest-items').css('display', 'block');
                    setTimeout(function () {
                        $('#invest-items').addClass('landing__how-items--active');
                    }, 5);
                }, 300);
                break;
        }
    },

    scrollToHelp: function () {
        $('.js-help').removeClass('landing__help--loaded');
        $('.js-help').addClass('landing__help--before-load');

        $('html, body').animate({
            scrollTop: $('#help-anchor').offset().top - 120
        }, 800, function(){
            $('.js-help').addClass('landing__help--loaded');
            $('.js-help').removeClass('landing__help--before-load');
        });
    },

    scrollToCreateInfo: function () {
        $('.js-create-item').addClass('landing__create-cards-item--before-load');
        let element = document.querySelector('#create-info-desktop');
        if (element.clientHeight === 0) {
            element = document.querySelector('#create-info-mobile');
        }

        $('html, body').animate({
            scrollTop: $('#' + element.id).offset().top
        }, 800, function(){
            $('.js-create-item').addClass('landing__create-cards-item--loaded');
            $('.js-create-item').removeClass('landing__create-cards-item--before-load');
        });
    }
});